<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("customer.new.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("customer.new.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="company">{{ $t("customer.new.field_company") }}</label>
            <InputText
              id="company"
              v-model="customer.company"
              aria-label="company"
              aria-describedby="company-error"
              :class="{ 'p-invalid': !!errors['company'] }"
            />
            <small
              v-if="!!errors['company']"
              id="company-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_company_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="first-name">{{
              $t("customer.new.field_contact_first_name")
            }}</label>
            <InputText
              id="first-name"
              v-model="customer.contact.name"
              aria-label="first-name"
              aria-describedby="first-name-error"
              :class="{ 'p-invalid': !!errors['name'] }"
            />
            <small
              v-if="!!errors['name']"
              id="first-name-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_contact_first_name_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="surname">{{
              $t("customer.new.field_contact_surname")
            }}</label>
            <InputText
              id="surname"
              v-model="customer.contact.surname"
              aria-label="surname"
              aria-describedby="surname-error"
              :class="{ 'p-invalid': !!errors['surname'] }"
            />
            <small
              v-if="!!errors['surname']"
              id="surname-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_contact_surname_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="email">{{
              $t("customer.new.field_contact_email")
            }}</label>
            <InputText
              id="email"
              v-model="customer.contact.email"
              aria-label="email"
              aria-describedby="email-error"
              :class="{ 'p-invalid': !!errors['email'] }"
            />
            <small
              v-if="!!errors['email']"
              id="email-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("customer.new.field_contact_email_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="time">{{ $t("customer.new.field_time") }}</label>
            <InputNumber
              id="time"
              v-model="totalTimeInMin"
              data-testid="time"
              show-buttons
              aria-label="time"
              suffix=" min."
              :min="0"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="size">{{ $t("customer.new.field_size") }}</label>
            <InputNumber
              id="size"
              v-model="maxStorageInGB"
              data-testid="size"
              show-buttons
              aria-label="size"
              suffix=" Gb."
              :min="0"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="expiresAt">{{
              $t("customer.new.field_expires")
            }}</label>
            <Calendar
              id="expiresAt"
              v-model="customer.expires"
              aria-label="expiresAt"
              :min-date="minDate"
              :month-navigator="true"
              :year-navigator="true"
              :show-icon="true"
              :year-range="rangeYears"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="language">{{
              $t("customer.new.field_language")
            }}</label>
            <input v-model="customer.locale" hidden data-testid="locale" />
            <Dropdown
              id="language"
              v-model="customer.locale"
              aria-label="language"
              option-label="language"
              option-value="code"
              :options="availableLocates"
            />
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit" data-testid="submit">
            {{ $t("common.register") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import {
  AVAILABLE_LOCALES,
  TOAST_LIFE,
  TRIAL_PROCESSING_TIME_IN_MIN,
  TRIAL_STORAGE_IN_GB,
} from "../config";
import Customer from "../models/Customer";
import { assertEmail, assertEmpty } from "../service/assert";
import { storageTransformer } from "../service/transformer/storageTransformer";
import { REGISTER_CUSTOMER } from "../store/modules/customer/customer.actions.type";
import { useExpireCalendar } from "./useExpireCalendar";

export default {
  name: "FormNewCustomer",
  components: { Button, Calendar, Dropdown, InputNumber, InputText },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const errors = ref([]);
    const customer = ref(Customer.empty());
    const availableLocates = AVAILABLE_LOCALES;
    const totalTimeInMin = ref(TRIAL_PROCESSING_TIME_IN_MIN);
    const maxStorageInGB = ref(TRIAL_STORAGE_IN_GB);

    const { minDate, rangeYears } = useExpireCalendar();

    async function handleSubmit() {
      if (validateInputs()) {
        customer.value.wage.totalTime = totalTimeInMin.value * 60;
        customer.value.wage.totalStorage = storageTransformer.gigaToBytes(
          maxStorageInGB.value
        );
        await store.dispatch(REGISTER_CUSTOMER, customer.value);
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("customer.new.toast_success"),
          life: TOAST_LIFE,
        });
        cleanForm();
      }
    }

    const cleanForm = () => {
      customer.value = Customer.empty();
      totalTimeInMin.value = TRIAL_PROCESSING_TIME_IN_MIN;
      maxStorageInGB.value = TRIAL_STORAGE_IN_GB;
    };

    function validateInputs() {
      errors.value = [];
      if (assertEmpty(customer.value.company)) {
        errors.value["company"] = true;
      }
      if (assertEmpty(customer.value.contact.name)) {
        errors.value["name"] = true;
      }
      if (assertEmpty(customer.value.contact.surname)) {
        errors.value["surname"] = true;
      }
      if (!assertEmail(customer.value.contact.email)) {
        errors.value["email"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      availableLocates,
      customer,
      errors,
      handleSubmit,
      totalTimeInMin,
      maxStorageInGB,
      minDate,
      rangeYears,
    };
  },
};
</script>
