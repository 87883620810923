<template>
  <section>
    <form-new-customer />
  </section>
</template>
<script>
import FormNewCustomer from "../../components/FormNewCustomer";

export default {
  name: "CustomerNew",
  components: { FormNewCustomer },
};
</script>
